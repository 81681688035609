import { graphql, navigate } from 'gatsby';

import DynamicZone from '../components/dynamic-zone/dynamic-zone';
import FloatingButton from '../components/floating-button/floating-button';
import Layout from '../components/layout';
import React from 'react';

export const query = graphql`
query ($slug: String!, $locale: String!) {
    cms {
      caseStudies(locale: $locale, where: {link: $slug}) {
        components {
          ... on STRAPI_ComponentComponentsHeader {
            __typename
            backgroundImageHeight
            contentOffset
            mobileContentOffset
            backgroundImage {
              url
            }
            primaryImage {
              url
            }
            headerSubtitle: subtitle
            title
            textBoxCustomClasses
          }
          ... on STRAPI_ComponentComponentsRichTextOnly {
            __typename
            richTextOnlyContent
            topPusherHeight
            topPusherHeightMobile
        }
          ... on STRAPI_ComponentComponentsImagesWithOffset {
            __typename
            content {
              image {
                url
              }
              title
              subtitle
            }
            bottomText
            contentOffset
            imagesWithOffsetScrollColor
            offsetComponentType
            topPusherHeight
            topPusherHeightMobile
            sectionTitle {
              description
              style
              tailwindClasses
              title
              titleIcon {
                url
              }
              ytLink
            }
          }
          ... on STRAPI_ComponentComponentsHorizontalScroll {
            __typename
            horizontalScrollColor
            HorizontalScrollCaseStudy {
              case_study {
                link
                logo {
                  url
                }
                slug
              }
            }
            sectionTitle {
              description
              style
              tailwindClasses
              title
              ytLink
              titleIcon {
                url
              }
            }
            topPusherHeight
            topPusherHeightMobile
          }
          ... on STRAPI_ComponentCaseStudiesCaseStudyIntro {
            __typename
            introContent
            caseStudyIntroTitle: title
            titleIcon {
              url
            }
            listItemUnderLogo {
              link
              title
              icon {
                url
              }
            }
            logo {
              url
            }
            topPusherHeight
            topPusherHeightMobile
          }
          ... on STRAPI_ComponentCaseStudiesRichTextWithSideImage {
            __typename
            richTextContent
            sectionTitle {
              ytLink
              titleIcon {
                url
              }
              title
              tailwindClasses
              style
              description
            }
            sideImage {
              url
            }
            topPusherHeight
            topPusherHeightMobile
          }
          ... on STRAPI_ComponentCaseStudiesRepeatableRichText {
            __typename
            richText {
              title
              content
              mobileContent
              titleIcon {
                url
              }
            }
            sectionTitle {
              ytLink
              titleIcon {
                url
              }
              title
              tailwindClasses
              style
              description
            }
            topPusherHeight
            topPusherHeightMobile
          }
          ... on STRAPI_ComponentCaseStudiesResults {
            __typename
            topPusherHeight
            topPusherHeightMobile
            columnResult {
              content
              icon {
                url
              }
              title
            }
            listIcon {
              url
            }
            sectionTitle {
              description
              style
              tailwindClasses
              title
              titleIcon {
                url
              }
              ytLink
            }
          }
          ... on STRAPI_ComponentUseCasesUseCasesSection {
          __typename
          topPusherHeightMobile
          topPusherHeight
          listBullet {
              url
          }
          sectionTitle {
            ytLink
            titleIcon {
              url
            }
            title
            tailwindClasses
            style
            description
          }
          quotationSection {
            authorCompany
            authorName
            authorRole
            quoatationIcon {
              url
            }
            quotation
            authorImage {
              url
            }
          }
          UseCasesColumns {
            title
            subtitle
            icon {
              url
            }
            backgroundImage {
              url
            }
          }
        }
        ... on STRAPI_ComponentComponentsSingleImage {
            __typename
            topPusherHeightMobile
            topPusherHeight
            image {
                url
            }
        }
        ... on STRAPI_ComponentHorizontalTabsHorizontalTabs {
          __typename
          topPusherHeightMobile
          topPusherHeight
          tabsContentColor
          tabs {
            type
            title
            subtitle
            scrollColor
            fullWidthImageItemsDirection
            label
            imagesMetadata
            imagesTailwindClasses
            images {
              url
            }
          }
          sectionTitle {
            description
            style
            tailwindClasses
            title
            titleIcon {
              url
            }
            ytLink
          }
          tabHeight
        }
        ... on STRAPI_ComponentComponentsBenefits {
          __typename
          topPusherHeightMobile
          topPusherHeight
          sectionTitle {
            ytLink
            titleIcon {
              url
            }
            title
            tailwindClasses
            style
            description
          }
          benefitsScrollColor
          Benefit {
            title
            subtitle
            image {
              url
            }
          }
        }
        ... on STRAPI_ComponentCaseStudiesItemsWithBigText {
          __typename
          itemsWithBigTextScrollColor
          topPusherHeight
          topPusherHeightMobile
          bigTextItem {
            itemContent
          }
        }
        ... on STRAPI_ComponentCaseStudiesPayoutSaving {
          __typename
          bigRichText
          smallRichText
          topPusherHeight
          topPusherHeightMobile
        }
        ... on STRAPI_ComponentCaseStudiesColumnsWithBorders {
          __typename
          columnWithOffsetImage {
            description
            image {
              url
            }
            title
          }
          mobileScrollColor
          sectionTitle {
            title
            titleIcon {
              url
            }
            ytLink
            tailwindClasses
            style
            description
          }
          textUnderColumns
          topPusherHeight
          topPusherHeightMobile
        }
        ... on STRAPI_ComponentCaseStudiesOffsetImagesWithSquare {
          __typename
          upperText
          topPusherHeight
          topPusherHeightMobile
          offsetImagesWithSquareScrollColor
          sectionTitle {
            description
            style
            tailwindClasses
            title
            titleIcon {
              url
            }
            ytLink
          }
          imageWithSquare {
            title
            image {
              url
            }
          }
        }
        ... on STRAPI_ComponentSeoSeo {
          __typename
          seoDescription
          seoLink
          seoTitle
          seoImages {
            url
          }
        }
        }
      }
    }
  }
`;

const CaseStudyTemplate = (props) => {
    const components = props.data.cms.caseStudies[0]?.components || null;

    if (!components) {
        if (typeof window !== `undefined`) {
            void navigate(`${props.pageContext.locale === `pl` ? `/pl` : ``}/404`);
        }
        return null;
    }

    return (
        <Layout location={props.location} locale={props.pageContext.locale}>
            <FloatingButton />
            <DynamicZone components={components} />
        </Layout>
    );
};

export default CaseStudyTemplate;
